/* eslint-disable @typescript-eslint/no-unused-vars */
import { fontBlack, fontRegular } from 'fonts'
import qs from 'qs'
import React from 'react'
import styled from 'styled-components'

import { UPDATE_OVERVIEW } from 'shared/questionnaires/actionTypes'
import {
  Questionnaire,
  QuestionnaireBooklet,
  QuestionnaireKey,
  QuestionnaireSummary,
  QuestionnaireType,
  QuestionnaireUser,
  QuestionnaireUserDataStatus,
  SectionProperty,
  SectionPropsBase,
  getQuestionnaireKey,
  scorelessQuestionnaireTypes,
} from 'shared/questionnaires/types'

import { Button, Column, H2, P, Padding, Row, Spacer, TextInput, headingCss } from 'common/ui'
import { DatePicker } from 'dashboards/common/DatePicker'
import { ObservationalCodeScores } from 'dashboards/common/ObservationalCodeQuestionnaire'
import { GenericTextSection } from 'session/sections/Text'
import { InputSection } from './sections/InputSection'
import { QuestionSection } from './sections/QuestionSection'

import { useUserState } from 'app/UserState'
import { ButtonLink } from 'common/LinkButton'
import { InfoTooltip } from 'common/Tooltip'
import { useMentorDashState } from 'dashboards/mentor/MentorDashboardState'
import { getQuestionnairesForBooklet } from 'dashboards/mentor/Questionnaires'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { CorkSection } from 'session/common/SectionsPanel'
import { GenericNotificationSection } from 'session/sections/Notification'
import { postJson } from 'utils/apiUtils'
import { arrayContainsAny } from 'utils/arrayUtils'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useQuestionnaireState } from './QuestionnaireState'
import { getSections } from './reducers'
import { validate } from './utils/questionnaireValidators'

export const sectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key }>
} = {
  text_sections: GenericTextSection,
  input_sections: InputSection,
  question_sections: QuestionSection,
  notification_sections: GenericNotificationSection,
}

const panelColours = {
  cadet: '#C2E3F4',
  teacher: '#FFE7CB',
  parent: '#D3F4CF',
  facilitator: '#D4C6EC',
}

const getSubmitButtonText = (status: QuestionnaireUserDataStatus) => {
  switch (status) {
    case 'submitting': return 'Submitting' // prettier-ignore
    case 'submission_error': return 'Error Saving' // prettier-ignore
    case 'submitted': return 'Submitted' // prettier-ignore
    default: return 'Submit' // prettier-ignore
  }
}

interface Props {
  userType: QuestionnaireUser
  booklet: QuestionnaireBooklet
  standalone?: boolean // whether or not there are any other questionnaires being presented before/after this one
  forceReadOnly?: boolean
  cadetMode?: boolean
  showManualScoreField?: boolean
  isEmbeddedInSession?: boolean
  publicMode?: boolean
  onPrev?: (questionnaireType: QuestionnaireKey) => void
  onNext?: (questionnaireType: QuestionnaireKey) => void
  onSubmit?: (data: QuestionnaireSummary) => Promise<any>
  onClose?: () => void
  closeLabel?: string
}

type GetQuestionnaireProps = {
  booklet: QuestionnaireBooklet
  userType: QuestionnaireUser | null
  type: QuestionnaireType | null
}

const ordinals: { [key: number]: string } = { 0: 'first', 1: 'second', 2: 'third', 3: 'fourth' }

export const QuestionnaireView: React.FC<Props> = ({
  userType,
  booklet,
  standalone,
  publicMode,
  forceReadOnly,
  cadetMode,
  showManualScoreField,
  isEmbeddedInSession,
  onSubmit,
  onPrev,
  onNext,
  onClose,
  closeLabel = 'Close',
}) => {
  const { accessToken } = useUserState()
  const {
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
    },
  } = useMentorDashState()

  const { questionnaireData, userDataStatus, setUserDataStatus, state, previewing, dispatch } = useQuestionnaireState()

  const [questionnaires, { loading: loadingQuestionnaires }] = useEndpoint<Questionnaire[]>(
    `/api/v1/${publicMode ? 'public/' : ''}questionnaires/list?${qs.stringify({
      booklet: booklet,
      user_type: userType,
      group_id: mentor ? mentor.group_id : undefined,
      public_mode: publicMode,
    })}`,
    []
  )

  const bookletQuestionnaires = getQuestionnairesForBooklet({ questionnaires: questionnaires || [], booklet, userType })

  const questionnaireIndex = questionnaireData
    ? bookletQuestionnaires.findIndex(({ id }) => id === questionnaireData?.id)
    : null
  const nextQuestionnaireType =
    questionnaireIndex !== null && questionnaireIndex < bookletQuestionnaires.length - 1
      ? bookletQuestionnaires[questionnaireIndex + 1].type
      : null
  const prevQuestionnaireType =
    questionnaireIndex !== null && questionnaireIndex > 0 ? bookletQuestionnaires[questionnaireIndex - 1].type : null

  const isFacilitatorCreated = state ? state.facilitator_created : false
  const readOnly = forceReadOnly || userDataStatus !== 'ready' || isFacilitatorCreated
  const isObservationalCode = !!questionnaireData && questionnaireData.type === 'observational-code'
  const started = state.responses.length > 0

  const handleSubmit = () => {
    if (!previewing) {
      setUserDataStatus('submitting')
      if (onSubmit) {
        onSubmit(state)
          .then(() => setUserDataStatus('submitted'))
          .catch(() => setUserDataStatus('submission_error'))
      } else {
        postJson(`/api/v1/questionnaire_overview`, state, getAuthRequestParams(accessToken), {
          retries: 0,
        })
          .then((data) => {
            console.log('Saved!', data)
            // dispatch({ type: UPDATE_STATE, state: { ...state, ...data } })
            setUserDataStatus('submitted')
          })
          .catch((e) => {
            // TODO: Do something
            setUserDataStatus('submission_error')
          })
      }
    } else {
      setUserDataStatus('submitting')
      setTimeout(() => setUserDataStatus('submitted'), 1000)
    }
  }

  const updateOverviewField = (field: keyof QuestionnaireSummary, value: string) =>
    dispatch({
      type: UPDATE_OVERVIEW,
      field,
      value,
    })

  if (!questionnaireData) return <p>Questionnaire data not loaded yet</p>

  const visibleChildName = userType !== 'facilitator' || isObservationalCode
  const editableChildName = visibleChildName && !readOnly && (publicMode || userType === 'facilitator')
  const visibleMentorName = userType !== 'cadet'
  const editableMentorName = visibleMentorName && !readOnly && (publicMode || userType === 'facilitator')
  const visibleFacilitatorName = userType !== 'facilitator' || isObservationalCode
  const editableFacilitatorName = false // visibleFacilitatorName && !readOnly && (isObservationalCode /* || or any other reasons here */) // prettier-ignore

  const validation = validate(questionnaireData, state)

  if (!forceReadOnly && !cadetMode && showManualScoreField && typeof state.manual_score === 'number') {
    if (state.manual_score < 0 || state.manual_score > 100) {
      validation.unshift({
        key: 'manual_score',
        valid: false,
        message: 'Please enter a valid score',
      })
    }
  }

  if (
    // (showManualScoreField && typeof state.manual_score !== 'number') ||
    isObservationalCode &&
    !state.observational_code_score
  ) {
    const questionComments = state.responses.map(({ value }) => value).filter((value) => typeof value === 'string')
    if (!questionComments.length) {
      validation.unshift({
        key: 'manual_score',
        valid: false,
        message: 'If no scores are calculated at least one comment field must be populated in order to submit',
      })
    }
  }

  if (visibleChildName && !state.cadet_name)
    validation.unshift({ key: 'cadet_name', valid: false, message: 'Cadet name is required' })
  if (visibleMentorName && !state.name)
    validation.unshift({
      key: 'name',
      valid: false,
      message: `${userType === 'teacher' ? 'Teacher' : 'Parent'} name is required`,
    })
  // if (visibleFacilitatorName && !state.facilitator_names)
  //   validation.unshift({ key: 'facilitator_names', valid: false, message: 'Facilitator name is required' })

  const validationErrorCount = validation.filter(({ valid }) => !valid).length
  const validationTooltipMessages = validation
    .filter(({ valid, message, key }) => !valid && (message || !key.match(/_\d+/)))
    .map(({ message, key }) => message || key.replace('_', ' '))

  return (
    <>
      {!cadetMode && (
        <>
          <Title>
            {userType} Questionnaire Booklet {booklet}
          </Title>
          <Spacer size="xs" />
          <Subtitle>{questionnaireData.public_title || questionnaireData.title}</Subtitle>
          <Spacer size="l" />
          <DetailSection>
            <Panel
              isEmbeddedInSession={isEmbeddedInSession}
              style={{ background: panelColours[userType], paddingTop: 15 }}
              className="questionnaire-panel">
              {visibleChildName && (
                <>
                  {forceReadOnly ? (
                    <P style={{ margin: 5 }}>
                      Child's name: <span style={{ fontWeight: 400 }} children={state.cadet_name} />
                    </P>
                  ) : (
                    <>
                      <P children={"Child's Name:"} />
                      <TextInput
                        value={state.cadet_name || ''}
                        onChange={(e) => updateOverviewField('cadet_name', e.target.value)}
                        placeholder={'Write here'}
                        disabled={!editableChildName}
                      />
                      <Spacer size="xs" />
                    </>
                  )}
                </>
              )}
              {visibleMentorName && (
                <>
                  {forceReadOnly ? (
                    <P style={{ margin: 5 }}>
                      {isObservationalCode
                        ? "Assessor's Name: "
                        : userType === 'teacher'
                          ? "Teacher's Name: "
                          : userType === 'parent'
                            ? "Parent's Name: "
                            : 'Name: '}
                      <span style={{ fontWeight: 400 }} children={state.name} />
                    </P>
                  ) : (
                    <>
                      <P
                        children={
                          isObservationalCode
                            ? "Assessor's Name: "
                            : userType === 'teacher'
                              ? "Teacher's Name:"
                              : userType === 'parent'
                                ? "Parent's Name:"
                                : 'Name:'
                        }
                      />
                      <TextInput
                        value={state.name || ''}
                        onChange={(e) => updateOverviewField('name', e.target.value)}
                        placeholder={'Write here'}
                        disabled={!editableMentorName}
                      />
                      <Spacer size="xs" />
                    </>
                  )}
                </>
              )}
              {visibleFacilitatorName && (
                <>
                  {forceReadOnly ? (
                    <P style={{ margin: 5 }}>
                      Facilitator(s) Name: <span style={{ fontWeight: 400 }} children={state.facilitator_names} />
                    </P>
                  ) : (
                    <>
                      <P children={'Facilitator(s) Name:'} />
                      <TextInput
                        value={state.facilitator_names || ''}
                        onChange={(e) => updateOverviewField('facilitator_names', e.target.value)}
                        placeholder={'Write here'}
                        disabled={!editableFacilitatorName}
                      />
                      <Spacer size="xs" />
                    </>
                  )}
                </>
              )}
              {forceReadOnly ? (
                <P style={{ margin: 5 }}>
                  Date: <span style={{ fontWeight: 400 }} children={state.date} />
                </P>
              ) : (
                <>
                  <P children={'Date:'} />
                  <DatePicker
                    value={state.date}
                    onChange={(value) => updateOverviewField('date', value)}
                    disabled={readOnly}
                  />
                </>
              )}
              {isEmbeddedInSession && <P>Note: The cadet cannot see this name and date panel.</P>}
            </Panel>
          </DetailSection>
          <Spacer />
        </>
      )}
      {isFacilitatorCreated ? (
        <Panel isEmbeddedInSession={isEmbeddedInSession} className="questionnaire-panel">
          <P>
            <strong>HQ Update: </strong>It looks like you have already done today's activity! Politely ask your Club
            Leader to check their dashboard.
          </P>
        </Panel>
      ) : (
        intersperseSpacers(
          (questionnaireData.panels || []).map((panel, idx) => {
            const sections = getSections(panel)
            return (
              <Panel key={idx} isEmbeddedInSession={isEmbeddedInSession} className="questionnaire-panel">
                {panel.title && (
                  <>
                    <Heading>{panel.title}</Heading>
                    <Spacer size="s" />
                  </>
                )}
                {sections
                  .map((sectionObj, i) => {
                    const Component = sectionComponents[sectionObj.property]
                    // @ts-ignore
                    return <Component key={i} {...sectionObj} />
                  })
                  .map((reactNode, idx) => (
                    <div
                      key={idx}
                      style={{ marginBottom: idx !== sections.length - 1 ? 15 : 0 }}
                      children={reactNode}
                    />
                  ))}
              </Panel>
            )
          }),
          'm'
        )
      )}
      {questionnaireData.type === 'observational-code' && (
        <>
          <Spacer size="m" />
          <Panel isEmbeddedInSession={isEmbeddedInSession} className="questionnaire-panel">
            <Heading>The Secret Agent Society Observer Scoring Sheet</Heading>
            <Spacer size="m" />
            <ObservationalCodeScores />
          </Panel>
        </>
      )}
      {!(cadetMode || forceReadOnly) && (
        <>
          {showManualScoreField && scorelessQuestionnaireTypes.indexOf(questionnaireData.type) < 0 && (
            <>
              <Spacer size="m" />
              <Panel isEmbeddedInSession={isEmbeddedInSession} className="questionnaire-panel">
                <H2>Facilitator Scoring Panel</H2>
                <P style={{ fontStyle: 'italic', opacity: 0.7 }}>
                  Note: The cadet cannot see this panel. <br />
                  The score entered will save to the cadet’s assessment summary table.
                </P>
                <Row alignItems="center" justifyContent="center">
                  <Column flex="none" paddingRight="20px">
                    <P children={'Score:'} />
                  </Column>
                  <Column flex="none">
                    <TextInput
                      type="number"
                      step={1}
                      min={0}
                      max={100}
                      value={
                        state.manual_score !== undefined && state.manual_score !== null
                          ? readOnly
                            ? String(Math.round(state.manual_score))
                            : String(state.manual_score)
                          : ' '
                      }
                      onChange={(e) =>
                        updateOverviewField(
                          'manual_score',
                          e.target.value && (parseInt(e.target.value) as unknown as string)
                        )
                      }
                      disabled={readOnly}
                      style={{ width: 145 }}
                    />
                  </Column>
                </Row>
                {readOnly && (
                  <P align="center" style={{ marginTop: 0, fontSize: 12 }}>
                    You can add or change a score later through the cadet assessment table.
                  </P>
                )}
              </Panel>
            </>
          )}
          <Spacer size="m" />
          <Panel isEmbeddedInSession={isEmbeddedInSession} className="questionnaire-panel">
            <Column justifyContent="center" alignItems="center">
              <Spacer size="m" />
              <Row style={{ width: '100%' }} alignItems="center">
                <Spacer flex="none" style={{ width: 145 }} />
                <Spacer flex="1 1 auto" />
                {userDataStatus === 'submitted' ? (
                  <Column flex="1 1 auto" alignItems="center" justifyContent="center">
                    {isObservationalCode ||
                    (!standalone && onNext && !nextQuestionnaireType && prevQuestionnaireType) ? (
                      <>
                        <H2 align="center">
                          You've finished{standalone ? '.' : ' all pages.'}
                          <br />
                          Please close / exit your {standalone ? 'questionnaire' : 'questionnaires'}.
                        </H2>
                        {onClose && (
                          <Padding size="m">
                            <Button children={closeLabel} onClick={onClose} />
                          </Padding>
                        )}
                        <img
                          src={require('../session/assets/questionnaire-booklet-complete.png')}
                          style={{ width: 320, maxWidth: '100%' }}
                          alt="Thank you!"
                        />
                      </>
                    ) : (
                      <>
                        {standalone || isEmbeddedInSession || !nextQuestionnaireType || questionnaireIndex === null ? (
                          <H2 align="center">Thank you. The questionnaire has been submitted</H2>
                        ) : (
                          <H2 align="center">Thank you for completing the {ordinals[questionnaireIndex]} page</H2>
                        )}
                        {cadetMode && (
                          <img
                            src={require('../session/assets/questionnaire-booklet-complete.png')}
                            style={{ width: 320, maxWidth: '100%' }}
                            alt="Thank you!"
                          />
                        )}
                      </>
                    )}
                  </Column>
                ) : (
                  <Row justifyContent="center">
                    <Button
                      size="l"
                      theme="parentGreen"
                      children={getSubmitButtonText(userDataStatus)}
                      onClick={handleSubmit}
                      disabled={(readOnly && userDataStatus !== 'submission_error') || validationErrorCount > 0}
                    />
                  </Row>
                )}
                <Spacer flex="1 1 auto" />
                {!standalone && onNext && nextQuestionnaireType ? (
                  <div style={{ width: 145, textAlign: 'right' }}>
                    {!started ? (
                      <ButtonLink
                        style={{ fontSize: 12 }}
                        onClick={() => onNext(getQuestionnaireKey(nextQuestionnaireType, userType))}
                        children="Skip"
                      />
                    ) : (
                      userDataStatus === 'submitted' && (
                        <Button
                          size="m"
                          theme="blue"
                          onClick={() => onNext(getQuestionnaireKey(nextQuestionnaireType, userType))}
                          children="Next"
                        />
                      )
                    )}
                  </div>
                ) : (
                  <Spacer flex="none" style={{ width: 145 }} />
                )}
              </Row>
              {userDataStatus !== 'submitted' && validationErrorCount > 0 && (
                <Row alignItems="center">
                  <P style={{ textAlign: 'center', color: '#E54400' }}>
                    {questionnaireData.type === 'observational-code' &&
                    !arrayContainsAny(
                      validation.map(({ key }) => key),
                      ['cadet_name', 'name', 'facilitator_names']
                    ) ? (
                      <>
                        In order to submit, you need to have filled out at least one section or comment field.
                        <br />
                        You will not be able to edit this form later.
                      </>
                    ) : (
                      `There ${
                        validationErrorCount > 1 ? `are ${validationErrorCount} questions` : `is 1 question`
                      } not yet answered. `
                    )}
                  </P>
                  <InfoTooltip
                    placement="top"
                    iconBackground="#E54400"
                    svgStyle={{ border: 'none' }}
                    tooltipStyle={{ minWidth: 300, fontSize: validationErrorCount > 4 ? 11 : undefined }}
                    content={
                      <>
                        <ul style={{ padding: '0 0 0 1em', margin: 0 }}>
                          {validationTooltipMessages.slice(0, 10).map((message, i) => (
                            <li key={i} children={message} />
                          ))}
                        </ul>
                        {(validationTooltipMessages.length > 10 ||
                          validationTooltipMessages.length < validationErrorCount) && (
                          <strong>
                            ...
                            <br />+{' '}
                            {validationErrorCount -
                              (validationTooltipMessages.length > 10 ? 10 : validationTooltipMessages.length)}{' '}
                            more
                          </strong>
                        )}
                      </>
                    }
                  />
                </Row>
              )}
              {/* <pre style={{ lineHeight: 1, fontSize: 12, overflow: 'auto', maxWidth: '100%' }} children={JSON.stringify(validation.filter(({ valid }) => !valid), null, 2)} /> */}
              {/*
                validation
                  .filter(({ valid }) => !valid)
                  .map(({ key }, i) => (
                    <div key={i}>{key}</div>
                  ))
              */}
              <Spacer size="m" />
            </Column>
          </Panel>
        </>
      )}
    </>
  )
}

export const Panel = styled.div<{ isEmbeddedInSession?: boolean }>`
  box-sizing: border-box;
  background: ${(p) => (p.isEmbeddedInSession ? p.theme.panelBg : '#fff')};
  width: 100%;
  min-width: 760px; // this is the safest min width to account for 5-option multichoice
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
`

const Heading = styled.h2`
  ${headingCss};
  ${fontBlack};
  color: ${(p) => p.theme.buttonBorderTopColor};
  font-size: 1.3rem;
`

const Title = styled(Heading)`
  color: #fff;
  ${CorkSection} & {
    color: #22acf3;
  }
`

const Subtitle = styled(Title)`
  font-size: 1.1rem;
`

const DetailSection = styled.div`
  ${Panel} {
    padding-top: 0;
  }

  ${P} {
    font-weight: 600;
    margin-bottom: 5px;
  }

  ${TextInput} {
    ${fontRegular};
    width: 100%;
    border: 1px solid ${(p) => p.theme.buttonBorderTopColor};
    box-shadow: none;
  }

  .DayPickerInput {
    width: 100%;
    box-sizing: border-box;

    & > input {
      ${fontRegular};
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid ${(p) => p.theme.buttonBorderTopColor};
      box-shadow: none;
    }

    & .DayPicker-Day--today {
      color: ${(p) => p.theme.buttonBorderTopColor};
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: ${(p) => p.theme.buttonBorderTopColor};
    }
  }
`
