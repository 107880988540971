import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { trainingPurple } from 'themes'
import { useParams, useHistory } from 'react-router-dom'

import {
  Questionnaire,
  PanelSection,
  ContentSection,
  QuestionnaireQuestionOption,
  QuestionnaireResponse,
  QuestionnaireUser,
  QuestionnaireType,
  QuestionnaireBooklet,
} from 'shared/questionnaires/types'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'

import { QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'
import { AppBackground, Container, Padding, P, Spacer } from 'common/ui'
import { Table } from 'reporting/common/ui'

import { useUserState } from 'app/UserState'
import { getUserDisplayName } from 'app/useGenericUser'
import { useQuestionnaireState, QuestionnaireStateProvider } from 'questionnaires/QuestionnaireState'
import { SocialSkillsFooter } from './Footer'
import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'

const generatePanel = (title: string, sections: ContentSection[]): PanelSection => {
  const generatedSections = sections.reduce(
    (obj, section, order) => ({
      ...obj,
      [section._owner]: [
        ...obj[section._owner as 'text_sections' | 'input_sections' | 'question_sections' | 'notification_sections'],
        { ...section, order },
      ],
    }),
    {
      text_sections: [],
      input_sections: [],
      question_sections: [],
      notification_sections: [],
    } as Pick<PanelSection, 'text_sections' | 'input_sections' | 'question_sections' | 'notification_sections'>
  )
  return {
    id: -1,
    _owner: '',
    _owner_id: -1,
    order: 0,
    title,
    ...generatedSections,
  }
}

const generateTextSection = (text: string): PanelSection['text_sections'][number] => ({
  id: -1,
  _owner: 'text_sections',
  _owner_id: -1,
  order: 0,
  text,
})

const generateInputSection = (
  partial: Pick<PanelSection['input_sections'][number], 'label' | 'type' | 'lines' | 'required' | 'maxLength'>,
  negativeId: number = -1
): PanelSection['input_sections'][number] => ({
  id: negativeId,
  _owner: 'input_sections',
  _owner_id: -1,
  order: 0,
  usage: 'individual', // N/A
  size: null,
  multiple_symbols: false,
  ...partial,
})

// const key = `panel_${section._owner_id}_${section.type}_${section.id}`
// const uniqueKey = `${key}_option_${option.id}`
const generateQuestionSection = (
  partial: Pick<
    PanelSection['question_sections'][number],
    'type' | 'score_range' | 'required' | 'title' | 'text' | 'terminology' | 'start_from' | 'question_options'
  >
): PanelSection['question_sections'][number] => ({
  id: 0,
  uid: '',
  _owner: 'question_sections',
  _owner_id: 0,
  order: 0,
  ...partial,
  question_options: partial.question_options.map((option, order) => ({
    ...option,
    order,
    id: (partial.start_from || 0) + order,
  })),
})

const generateQuestionOption = (
  optionLabels: [string, string, string],
  title: string,
  reverse_scored: boolean = false
): QuestionnaireQuestionOption => ({
  id: -1,
  order: 0,
  reverse_scored,
  title,
  label: '',
  placeholder: '',
  text: '',
  optionLabels,
})

const headings = [
  'Non-verbal Communication',
  'Conversation skills',
  'Cooperation skills',
  'Conflict resolution',
  'Emotion regulation',
] as const

const developmentalOptionsMinMax: [string, string, string] = [
  'Developmentally appropriate level',
  'Slightly too little / too much',
  'Significantly too little / too much',
]
const developmentalOptionsMax: [string, string, string] = [
  'Developmentally appropriate level',
  'Slightly too much',
  'Significantly too much',
]
const truthyOptions: [string, string, string] = ['Not true', 'Somewhat true', 'Mostly true']

export const observationalCodeQuestionnaire: Questionnaire = {
  id: -1,
  uid: '',
  title: 'The Secret Agent Society Observer Coding Form',
  type: QuestionnaireType.observational_code,
  user_type: QuestionnaireUser.facilitator,
  order: 7,
  panels: [
    generatePanel('The Secret Agent Society Observer Coding Form', [
      generateTextSection(
        `This observational tool cannot be changed after partial completion. It must be completed in one attempt. If this is not possible, please use the print version of this tool.`
      ),
      generateTextSection(`Instructions: \nPlease fill out this form after observing a child for 15-20 minutes. Rate how often the child displayed each target behaviour relative to an average child of the same age.
        If you do not have the opportunity to see a target behaviour, or were unable to judge the child’s competency from the behaviour observed, please circle N/A.`),
      generateInputSection(
        {
          label: 'Description of Activities Observed',
          type: 'textarea',
          lines: 3,
          required: false,
          maxLength: 5000,
        },
        -1
      ),
    ]),
    generatePanel(headings[0], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 1,
        terminology: '',
        required: false,
        question_options: [
          // 1 - 4 Non-verbal Communication
          generateQuestionOption(developmentalOptionsMinMax,`The child makes eye contact when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child smiles and laughs when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child varies the pitch and volume of his/her voice when talking to others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The distance the child places between him/herself and others when interacting with them is`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(headings[1], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 5,
        terminology: '',
        required: false,
        question_options: [
          // 5 - 12 Conversation skills
          generateQuestionOption(developmentalOptionsMinMax,`The child takes appropriate steps to start conversations with other children`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child takes appropriate steps to start conversations with adults`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child is polite when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child interrupts others when they are speaking`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child asks other people questions about themselves`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child talks about him/herself or his/her interests when interacting with others`), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child politely ends conversations with other children`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child politely ends conversations with adults`, true), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(headings[2], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 13,
        terminology: '',
        required: false,
        question_options: [
          // 13 - 19 Cooperation skills
          generateQuestionOption(developmentalOptionsMinMax,`The child asks other children to play`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child asks other people for help`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child offers to help others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child shares with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child takes over when doing activities`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child follows the rules when playing with other children`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child compliments and congratulates other children`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(headings[3], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 20,
        terminology: '',
        required: false,
        question_options: [
          // 20 - 26 Conflict resolution
          generateQuestionOption(truthyOptions, `The child calmly resolves disagreements with others`, true), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child apologises when he/she upsets someone else`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child ignores bullying/teasing by others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child verbally stands up for him/herself when being bullied`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child physically retaliates when frustrated by others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child reports bullying events to adults`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child makes fun of other children`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(headings[4], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 27,
        terminology: '',
        required: false,
        question_options: [
          // 27 - 30 Emotion Regulation
          generateQuestionOption(truthyOptions, `The child uses appropriate strategies to calm down when feeling anxious/worried`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child uses appropriate strategies to calm down when feeling angry/frustrated`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child stays calm when he/she makes a mistake`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child persists with activities that he/she finds difficult`, true), // prettier-ignore
        ],
      }),
    ]),
    generatePanel('', [
      generateInputSection(
        {
          label: 'Additional Comments',
          type: 'textarea',
          lines: 7,
          required: false,
          maxLength: 5000,
        },
        -2
      ),
    ]),
  ].map((panel, order) => ({ ...panel, order })),
}

export const ObservationalCodeQuestionnaire: React.FC = () => {
  return (
    <QuestionnaireStateProvider>
      <ObservationalCodeQuestionnaireMain />
    </QuestionnaireStateProvider>
  )
}

export const ObservationalCodeQuestionnaireMain: React.FC = () => {
  const history = useHistory()
  const { drupalProfile } = useUserState()
  const { questionnaireBooklet, providerUid } = useParams<{ questionnaireBooklet: string; providerUid: string }>()
  const {
    dispatch,
    state: userData,
    userDataStatus,
    setUserDataStatus,
    questionnaireData,
    setQuestionnaireData,
  } = useQuestionnaireState()

  useEffect(() => {
    if (!questionnaireData) {
      console.log('setting questionnaire data')
      setQuestionnaireData(observationalCodeQuestionnaire)
    }
  }, [questionnaireData, setQuestionnaireData])

  // This set up initial state data if the questionnaire hasn't been submitted before
  useEffect(() => {
    if (
      drupalProfile &&
      questionnaireData &&
      userDataStatus !== 'ready' &&
      userDataStatus !== 'submitted' &&
      userDataStatus !== 'submitting'
    ) {
      setUserDataStatus('ready')
      dispatch({
        type: UPDATE_STATE,
        state: {
          ...userData,
          score: undefined,
          manual_score: undefined,
          mentor_id: undefined,
          cadet_mentor_id: undefined,
          provider_uid: providerUid,
          drupal_user_id: parseInt(drupalProfile.user_id),
          questionnaire_booklet: parseInt(questionnaireBooklet) as QuestionnaireBooklet,
          questionnaire_type: 'observational-code',
          date: new Date().toISOString().split('T')[0],
          name: '',
          cadet_name: undefined,
          facilitator_names: `${getUserDisplayName({ authProvider: 'sst', drupalProfile, profileName: '' })}`,
          responses: [],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataStatus, drupalProfile, questionnaireData])

  return (
    <ThemeProvider theme={trainingPurple}>
      <AppBackground>
        <Padding size="m">
          <Container size="m">
            <QuestionnaireContent
              userType={QuestionnaireUser.facilitator}
              booklet={parseInt(questionnaireBooklet) as QuestionnaireBooklet}
              standalone
              onClose={() => history.push(`${baseUrl}/assessment`)}
              closeLabel="Back to Assessment"
            />
            <SocialSkillsFooter />
          </Container>
        </Padding>
      </AppBackground>
    </ThemeProvider>
  )
}

const ranges: [number, number][] = [
  [1, 4],
  [5, 12],
  [13, 19],
  [20, 26],
  [27, 30],
]

const getUserResponse = (index: number, responses: QuestionnaireResponse[]) =>
  responses.find((res) => res.unique_key === `panel_0_observational_0_option_${index}`)

const getUserResponsesFromRange = (start: number, end: number, responses: QuestionnaireResponse[]) =>
  [...Array(end - (start - 1))]
    .map((_, i) => getUserResponse(start + i, responses))
    .filter((_) => _) as QuestionnaireResponse[]

const getResponsesTotal = (responses: QuestionnaireResponse[]) =>
  responses.reduce(
    (total, response) => total + (response.score !== null && response.score !== undefined ? response.score + 1 : 0),
    0
  )

const getResponsesSubscale = (responses: QuestionnaireResponse[]) =>
  getResponsesTotal(responses) / responses.length || 0

const getSubscaleScores = (responses: QuestionnaireResponse[]) =>
  ranges.map((range) => {
    const rangeResponses = getUserResponsesFromRange(range[0], range[1], responses)
    return getResponsesSubscale(rangeResponses)
  })

const getSubscaleTotal = (subscaleScores: number[]) => subscaleScores.reduce((total, subscale) => total + subscale, 0)

export const getSubscaleOverall = (responses: QuestionnaireResponse[]) => {
  const subscaleScores = getSubscaleScores(responses)
  const subscaleTotal = getSubscaleTotal(subscaleScores)
  const applicableSubscaleScores = subscaleScores.filter((num) => num > 0)
  return subscaleTotal / applicableSubscaleScores.length || 0
}

export const ObservationalCodeScores: React.FC = () => {
  const { state: userData } = useQuestionnaireState()

  const subscaleScores = getSubscaleScores(userData.responses)
  const subscaleTotal = getSubscaleTotal(subscaleScores)
  const applicableSubscaleScores = subscaleScores.filter((num) => num > 0)
  const subscaleOverall = subscaleTotal / applicableSubscaleScores.length || 0

  return (
    <>
      {ranges.map((range, i) => (
        <ObservationalCodeScoreSegment
          key={i}
          title={headings[i]}
          start={range[0]}
          end={range[1]}
          responses={getUserResponsesFromRange(range[0], range[1], userData.responses)}
        />
      ))}
      <Spacer size="s" />
      <Table style={{ borderWidth: 5, borderLeft: '5px solid #abb3dc' }}>
        <tbody>
          <tr>
            <th colSpan={2}>
              <strong>Total Social Skills Score</strong>
            </th>
          </tr>
          <tr>
            <td>Add all subscale scores</td>
            <td style={{ width: 200 }}>
              {String(subscaleTotal).indexOf('.') > 0 ? subscaleTotal.toFixed(2) : subscaleTotal}
            </td>
          </tr>
          <tr>
            <td>Divide by number of subscales for which a score could be obtained</td>
            <td>{applicableSubscaleScores.length}</td>
          </tr>
          <tr>
            <td>
              <strong style={{ fontSize: 20 }}>Total Social Skills Score</strong>
            </td>
            <td>
              <strong style={{ fontSize: 20 }}>
                {String(subscaleOverall).indexOf('.') > 0 ? subscaleOverall.toFixed(2) : subscaleOverall}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
      <P>
        <i>
          Scores range from 1 to 3. Lower scores reflect greater levels of social skill. <br />
          If several items in a subscale are marked N/A, caution is warranted in interpreting the subscale and total
          scores. In those instances, the scores are unlikely to provide a valid indicator of a child’s skill level in
          the domain(s) being assessed.
        </i>
      </P>
    </>
  )
}

const ObservationalCodeScoreSegment: React.FC<{
  title: string
  start: number
  end: number
  responses: QuestionnaireResponse[]
}> = ({ title, start, end, responses }) => {
  const questionsCount = end - (start - 1)
  const total = getResponsesTotal(responses)
  const subscale = getResponsesSubscale(responses)
  const responsesCount = responses.filter(({ score }) => typeof score === 'number').length
  return (
    <>
      {/* <h2>{title}</h2> */}
      <Table style={{ marginTop: -2 }}>
        <tbody>
          <tr>
            <th colSpan={2}>{title}</th>
          </tr>
          <tr>
            <td>
              {responsesCount} / {questionsCount} questions answered, totalling
            </td>
            <td style={{ width: 200 }}>
              {total} <small>out of a possible {questionsCount * 3}</small>
            </td>
          </tr>
          <tr>
            <td>Divide by the number of questions answered</td>
            <td>{responsesCount}</td>
          </tr>
          <tr>
            <td>
              <strong>{title} Subscale Score is</strong>
            </td>
            <td>
              <strong>{!subscale ? 'N/A' : String(subscale).indexOf('.') > 0 ? subscale.toFixed(2) : subscale}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
