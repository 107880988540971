import uniq from 'lodash/uniq'
import React, { Fragment, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { parentGreen } from 'themes'

import { NavRouteProps } from 'dashboards/types'
import { baseUrl } from './MentorDashboard'

import {
  AssessmentResource,
  AssessmentResourceGroup,
  assessmentMaterialsResources,
  bookletTitles,
} from 'dashboards/constant/resources'
import { QuestionnaireModuleCode } from 'shared/dashboard/moduleCodes'
import {
  Questionnaire,
  QuestionnaireBooklet,
  QuestionnaireSummarySaved,
  QuestionnaireUser,
  getQuestionnaireKey,
  questionnaireKeyLabels,
  questionnaireKeyLookup,
} from 'shared/questionnaires/types'

import { CheckboxLabel } from 'common/Checkbox'
import { LinkButton } from 'common/LinkButton'
import { SpinnerWithLabel } from 'common/Spinner'
import { Button, Column, H3, P, Row, Spacer } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Page } from 'dashboards/common/Page'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'

import { getThemeFromResourceType } from 'dashboards/facilitator/AssessmentMaterials'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { SessionEntity } from 'shared/dashboard/types'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useMentorDashState } from './MentorDashboardState'

const bookletMap: {
  [key in QuestionnaireModuleCode]: Exclude<QuestionnaireSummarySaved['questionnaire_booklet'], undefined>
} = {
  'parent-intro': 1,
  'parent-info-1': 1,
  '9': 2,
  '9b': 2,
  'parent-info-4': 2,
  'follow-up-1': 3,
  'follow-up-1b': 3,
  'follow-up-2': 4,
  'follow-up-2b': 4,
}

export const Questionnaires: React.FC<NavRouteProps> = ({ route }) => {
  const {
    endpoints: {
      group: { value: group, loading: loadingGroup, fetch: refetchGroup },
      mentor: { value: mentor, loading: loadingMentor },
      primaryMentor: { value: primaryMentor, loading: loadingPrimaryMentor },
    },
  } = useMentorDashState()
  const [sessions, { loading: loadingSessions, fetch: refetchSessions }] = useEndpoint<SessionEntity[]>(
    mentor && mentor.uid ? `/api/v1/sessions?mentor_uid=${mentor.uid}` : null,
    [],
    { refetchDespiteCachedValue: true }
  )
  const [questionnaires, { loading: loadingQuestionnaires }] = useEndpoint<Questionnaire[]>(
    group ? `/api/v1/questionnaires/list?group_id=${group.id}` : null,
    [],
    { refetchDespiteCachedValue: true }
  )
  const [questionnaireSummaries, { loading: loadingQuestionnaireSummaries, fetch: refetchQuestionnaireSummaries }] =
    useEndpoint<QuestionnaireSummarySaved[]>(
      mentor && primaryMentor ? `/api/v1/mentors/${mentor.uid}/questionnaires` : null,
      undefined,
      {
        refetchDespiteCachedValue: true,
      }
    )

  useEffect(() => {
    if (!group && !loadingGroup) refetchGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loadingMentor || loadingPrimaryMentor)
    return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />

  if (loadingSessions || loadingQuestionnaireSummaries || loadingQuestionnaires)
    return <Page route={route} children={<SpinnerWithLabel label="Loading data..." />} />

  if (mentor && !mentor.permission_parent_questionnaires && !mentor.permission_teacher_questionnaires)
    return <NotAuthorizedPage />

  const unlockedBooklets = sessions
    ? uniq(
        sessions.reduce(
          (acc, session) =>
            session.type === 'parent' && session.concluded && Object.keys(bookletMap).includes(session.module_code)
              ? [...acc, bookletMap[session.module_code as QuestionnaireModuleCode]]
              : acc,
          [] as number[]
        )
      )
    : []

  const hasParentAccess = mentor?.permission_parent_questionnaires || false
  const hasTeacherAccess = mentor?.permission_teacher_questionnaires || false

  // construct actual booklets from the CMS questionnaires data and use that instead of static resources now
  const unlockedResourceGroups: AssessmentResourceGroup[] = uniq([
    1,
    ...unlockedBooklets,
  ] as QuestionnaireBooklet[]).map((booklet) => {
    const resources: AssessmentResource[] = []
    const cadetStaticResource = assessmentMaterialsResources
      .find((r) => r.booklet === booklet)
      ?.resources.find((r) => r.userType === QuestionnaireUser.cadet)
    resources.push({
      title: `Child Questionnaire Booklet ${booklet}`,
      userType: QuestionnaireUser.cadet,
      injectQuestionnaireKeys: cadetStaticResource?.injectQuestionnaireKeys,
      interactivePdf: cadetStaticResource?.interactivePdf || null,
      staticPdf: cadetStaticResource?.staticPdf || null,
    })
    if (hasParentAccess) {
      const parentStaticResource = assessmentMaterialsResources
        .find((r) => r.booklet === booklet)
        ?.resources.find((r) => r.userType === QuestionnaireUser.parent)
      resources.push({
        title: `Parent Questionnaire Booklet ${booklet}`,
        userType: QuestionnaireUser.parent,
        injectQuestionnaireKeys: cadetStaticResource?.injectQuestionnaireKeys,
        interactivePdf: parentStaticResource?.interactivePdf || null,
        staticPdf: parentStaticResource?.staticPdf || null,
      })
    }
    if (hasTeacherAccess) {
      const teacherStaticResource = assessmentMaterialsResources
        .find((r) => r.booklet === booklet)
        ?.resources.find((r) => r.userType === QuestionnaireUser.teacher)
      resources.push({
        title: `Teacher Questionnaire Booklet ${booklet}`,
        userType: QuestionnaireUser.teacher,
        injectQuestionnaireKeys: cadetStaticResource?.injectQuestionnaireKeys,
        interactivePdf: teacherStaticResource?.interactivePdf || null,
        staticPdf: teacherStaticResource?.staticPdf || null,
      })
    }
    return {
      title: bookletTitles[booklet],
      booklet: booklet,
      resources,
    }
  })

  return (
    <Page route={route}>
      <P style={{ marginTop: 0 }}>
        The SAS Small Group Program includes assessment tools to assist the SAS Facilitator to adapt the program to meet
        individual Cadet needs and also to monitor and report progress over time.
      </P>
      <P>
        At 4 points across the program, 4 Questionnaire Booklets will be unlocked below. Your SAS Facilitator may prompt
        you to complete each booklet in an individual consultation, while in Parent Group Meetings or via email
        notification. If you have already completed a booklet, you will be able to view your previous entries. You will
        not need to repeat questionnaires if you receive an additional reminder notification email.
      </P>
      <P>If you are ever unsure, please contact your SAS Facilitator.</P>
      <br />
      {/* <pre>{JSON.stringify({ questionnaires, unlockedResourceGroups }, null, 2)}</pre> */}
      {unlockedResourceGroups.map((resourceGroup) => (
        <Fragment key={resourceGroup.booklet}>
          <QuestionnaireSet
            questionnaires={questionnaires || []}
            resourceGroup={resourceGroup}
            overviews={questionnaireSummaries || []}
            hasParentAccess={hasParentAccess}
            hasTeacherAccess={hasTeacherAccess}
          />
          <Spacer size="l" />
        </Fragment>
      ))}
      <Button
        size="xs"
        theme="grey"
        children="Refresh"
        onClick={() => {
          refetchSessions()
          refetchQuestionnaireSummaries()
        }}
      />
    </Page>
  )
}

export const getQuestionnairesForBooklet = ({
  questionnaires,
  booklet,
  userType,
}: {
  questionnaires: Questionnaire[]
  booklet?: QuestionnaireBooklet
  userType: QuestionnaireUser
}): Questionnaire[] => {
  return questionnaires.filter((q) => (!q.booklet ? true : +q.booklet === booklet) && q.user_type === userType)
}

interface QuestionnaireSetProps {
  questionnaires: Questionnaire[]
  resourceGroup: AssessmentResourceGroup
  overviews: QuestionnaireSummarySaved[]
  hasTeacherAccess: boolean
  hasParentAccess: boolean
}

const QuestionnaireSet: React.FC<QuestionnaireSetProps> = ({
  questionnaires,
  resourceGroup,
  overviews,
  hasParentAccess,
  hasTeacherAccess,
}) => {
  const { title, booklet } = resourceGroup
  const availableResources = resourceGroup.resources.filter(
    ({ userType }) =>
      (userType === QuestionnaireUser.teacher && hasTeacherAccess) ||
      (userType === QuestionnaireUser.parent && hasParentAccess)
  )
  return (
    <>
      <HeaderHr children={title} />
      <Cells>
        {availableResources.map(({ title, userType /*,  questionnaireKeys*/ }) => {
          const theme = getThemeFromResourceType(userType)
          const resourceQuestionnaires = getQuestionnairesForBooklet({ questionnaires, booklet, userType })
          const questionnaireTypeOverviews = resourceQuestionnaires.map((questionnaire) => {
            const questionnaireKey = getQuestionnaireKey(questionnaire.type, questionnaire.user_type)
            return {
              questionnaireKey,
              overview: overviews.find(
                (overview) =>
                  overview.questionnaire_type === questionnaireKey && overview.questionnaire_booklet === booklet
              ),
            }
          })
          const firstQuestionnaireKey = resourceQuestionnaires[0]
            ? getQuestionnaireKey(resourceQuestionnaires[0].type, resourceQuestionnaires[0].user_type)
            : null
          const started = overviews.filter((overview) => overview.questionnaire_booklet === booklet).length > 0
          const completed =
            questionnaireTypeOverviews.filter(({ overview }) => !!overview).length === questionnaires.length
          const resumeQuestionnaireKey =
            !started || completed
              ? firstQuestionnaireKey
              : questionnaireTypeOverviews.find(({ overview }) => !overview)?.questionnaireKey || firstQuestionnaireKey
          const resumeQuestionnaireType = resumeQuestionnaireKey
            ? questionnaireKeyLookup[resumeQuestionnaireKey]?.type
            : null

          if (!resumeQuestionnaireType) {
            console.warn('Could not find', resumeQuestionnaireKey)
            return null
          }

          return (
            <Cell key={`${booklet}-${userType}`} accent={theme}>
              <CellInner>
                <Column flex="1 1 auto">
                  <H3>{title}</H3>
                </Column>
                <Row flex="none" alignItems="center">
                  <ThemeProvider theme={parentGreen}>
                    {intersperseSpacers(
                      questionnaireTypeOverviews.map(({ questionnaireKey, overview }, index) => (
                        <CheckboxLabel
                          key={`${index}_${questionnaireKey}`}
                          size="xs"
                          style={{ opacity: 0.5, fontSize: 12, marginBottom: 1, marginLeft: -4, paddingRight: 10 }}
                          children={
                            <>
                              {!!overview && <span style={{ color: '#4db900', paddingRight: 5 }} children="✔︎" />}
                              {questionnaireKeyLabels[questionnaireKey].replace(/\s\([\w\s]+\)$/, '')}
                            </>
                          }
                        />
                      )),
                      's'
                    )}
                  </ThemeProvider>
                  <Spacer size="s" />

                  <LinkButton
                    theme={completed ? 'grey' : theme}
                    size="s"
                    children={completed ? 'View' : started ? 'Resume' : 'Begin'}
                    to={`${baseUrl}/questionnaires/${booklet}/${userType}/${resumeQuestionnaireType}`}
                  />
                </Row>
              </CellInner>
            </Cell>
          )
        })}
      </Cells>
    </>
  )
}
