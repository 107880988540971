import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { orange, parentGreen } from 'themes'
import qs from 'qs'

import { NavRouteProps } from 'dashboards/types'
import { baseUrl } from './MentorDashboard'
import {
  Questionnaire,
  QuestionnaireType,
  QuestionnaireSummary,
  QuestionnaireKey,
  QuestionnaireUser,
  questionnaireKeyLookup,
  QuestionnaireBooklet,
} from 'shared/questionnaires/types'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useMentorDashData, useMentorDashState } from './MentorDashboardState'

import { Container, P } from 'common/ui'
import { SpinnerWithLabel } from 'common/Spinner'
import { QuestionnaireStateProvider, useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'
import { Page } from 'dashboards/common/Page'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'
import { FacilitatorLookupEntity, MentorEntity } from 'shared/dashboard/types'
import { SocialSkillsFooter } from 'dashboards/common/Footer'

interface RouteProps {
  booklet: '1' | '2' | '3' | '4'
  userType: QuestionnaireUser.teacher | QuestionnaireUser.parent
  questionnaireType: QuestionnaireType
}

// TODO: attempt to find an existing questionnaire, if exists setQuestionnaireState and put in viewOnly mode

export const QuestionnaireDashboardView: React.FC<NavRouteProps> = ({ route }) => {
  const { userType } = useParams<RouteProps>()
  return (
    <QuestionnaireStateProvider>
      <ThemeProvider theme={userType === 'parent' ? parentGreen : orange}>
        <Page route={route} hideTitle hideFooter useThemeBackground>
          <Container size="m">
            <QuestionnaireView route={route} />
            <SocialSkillsFooter />
          </Container>
        </Page>
      </ThemeProvider>
    </QuestionnaireStateProvider>
  )
}

export const QuestionnaireView: React.FC<NavRouteProps> = ({ route }) => {
  const history = useHistory()
  const { userType, booklet, questionnaireType } = useParams<RouteProps>()
  const { cadetPrimaryMentorUid } = useMentorDashState()

  const [mentor, loadingMentor] = useMentorDashData('mentor')
  const [group, loadingGroup] = useMentorDashData('group')

  const permissionToCheck =
    userType === 'teacher' ? 'permission_teacher_questionnaires' : 'permission_parent_questionnaires'

  const isValidMentor = mentor && !!mentor[permissionToCheck]
  const mentorIsPrimaryMentor = !!mentor && mentor.primary_licence_holder

  const [fetchedLicenceHoldingMentor] = useEndpoint<MentorEntity>(
    mentor && !mentor.primary_licence_holder ? `/api/v1/mentors/${cadetPrimaryMentorUid}` : null
  )
  const primaryMentor = mentorIsPrimaryMentor ? (mentor as MentorEntity) : fetchedLicenceHoldingMentor

  const [facilitator, { loading: facilitatorLoading }] = useEndpoint<FacilitatorLookupEntity>(
    mentor ? `/api/v1/mentors/${mentor.uid}/facilitator/${mentor.group_id}` : null
  )

  const {
    dispatch,
    state: userData,
    questionnaireData,
    setQuestionnaireData,
    userDataStatus,
    setUserDataStatus,
  } = useQuestionnaireState()

  const [questionnaire, { loading: loadingQuestionnaire, errorLoading: errorLoadingQuestionnaire }] =
    useEndpoint<Questionnaire>(
      isValidMentor ? `/api/v1/questionnaires/${booklet}/${userType}/${questionnaireType}` : null
    )

  /**
   * TODO: Provide questionnaires to QuestionnaireContent component so that the next/prev one get be determined by CMS data instead of static resources
   */

  const [questionnaireUserData, { loading: loadingQuestionnaireUserData }] = useEndpoint<QuestionnaireSummary>(
    mentor && isValidMentor && questionnaire
      ? `/api/v1/questionnaires/overview?${qs.stringify({
          mentor_uid: mentor.uid,
          user_type: userType,
          type: questionnaireType,
          booklet: booklet,
        })}`
      : null
  )

  useEffect(() => {
    if (loadingQuestionnaireUserData && userDataStatus !== 'loading') setUserDataStatus('loading')
    else if (!loadingQuestionnaireUserData && userDataStatus === 'loading')
      setUserDataStatus(questionnaireUserData ? 'submitted' : 'empty')
  }, [loadingQuestionnaireUserData, questionnaireUserData, userDataStatus, setUserDataStatus])

  useEffect(() => {
    if (questionnaire) setQuestionnaireData(questionnaire)
  }, [questionnaire, setQuestionnaireData])

  useEffect(() => {
    document.getElementsByTagName('main').item(0)?.scrollTo(0, 0)
    console.log('resetting userDataStatus to ready because of route change')
    setUserDataStatus('ready')
  }, [booklet, questionnaireType, setUserDataStatus])

  useEffect(() => {
    if (questionnaireUserData) {
      console.log('Loaded existing user data!')
      dispatch({ type: UPDATE_STATE, state: questionnaireUserData })
      setUserDataStatus('submitted')
    } else {
      setUserDataStatus('empty')
      console.log('Questionnaire user data request worked but was blank so questionnaire is not saved yet')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireUserData])

  // This set up initial state data if the questionnaire hasn't been submitted before
  useEffect(() => {
    if (
      mentor &&
      primaryMentor &&
      group &&
      (facilitator || (!facilitator && !facilitatorLoading)) &&
      questionnaireData &&
      userDataStatus === 'empty'
    ) {
      console.log('Initializing questionnaire summary data')
      setUserDataStatus('ready')
      dispatch({
        type: UPDATE_STATE,
        state: {
          ...userData,
          mentor_id: mentor.id,
          cadet_mentor_id: primaryMentor.id,
          provider_uid: group.provider_uid,
          drupal_user_id: mentor.drupal_user_id,
          questionnaire_booklet: parseInt(booklet) as QuestionnaireBooklet,
          questionnaire_type: questionnaireType as QuestionnaireKey,
          date: new Date().toISOString().split('T')[0],
          name: `${mentor.first_name} ${mentor.last_name}`,
          cadet_name: `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}`,
          facilitator_names: facilitator ? facilitator.name : undefined,
          responses: [],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userDataStatus,
    // questionnaireData,
    questionnaireUserData,
    // here down is just what's necessary to fill out default state
    mentor,
    primaryMentor,
    group,
    facilitator,
    facilitatorLoading,
  ])

  const navigateToOther = (questionnaireType: QuestionnaireKey) => {
    const { type: category } = questionnaireKeyLookup[questionnaireType]
    history.push(`${baseUrl}/questionnaires/${booklet}/${userType}/${category}`)
  }

  if (mentor && !isValidMentor) return <NotAuthorizedPage />

  if (loadingMentor || loadingGroup || loadingQuestionnaire || loadingQuestionnaireUserData)
    return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />

  if (!questionnaire || errorLoadingQuestionnaire)
    return <Page route={route} children={<P>An error occurred while loading data, try refreshing.</P>} />

  return (
    <QuestionnaireContent
      userType={userType as QuestionnaireUser.teacher | QuestionnaireUser.parent}
      booklet={+booklet as QuestionnaireBooklet}
      onNext={navigateToOther}
      onPrev={navigateToOther}
      onClose={() => history.push(`${baseUrl}/questionnaires`)}
      closeLabel="Back to Portal"
    />
  )
}
