import qs from 'qs'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { NavRouteProps } from 'dashboards/types'
import { assessmentMaterialsResources, AssessmentResource, AssessmentResourceGroup } from '../constant/resources'

import Modal from 'app/Modal'
import { Field } from 'common/Field'
import { SelectField } from 'common/FieldInputs'
import { AnchorButton, ButtonLink } from 'common/LinkButton'
import { InfoTooltip } from 'common/Tooltip'
import { Button, Column, CUT_TL, CUT_TR, H3, P, Panel, Row, Spacer, TextInput } from 'common/ui'
import { CellInner as _CellInner, Cell, Cells } from 'dashboards/common/Cell'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Page } from 'dashboards/common/Page'
import { PromptModal } from 'dashboards/common/PromptModal'

import { useUserState } from 'app/UserState'
import { observationalCodeQuestionnaire } from 'dashboards/common/ObservationalCodeQuestionnaire'
import { getQuestionnairesForBooklet } from 'dashboards/mentor/Questionnaires'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { Questionnaire } from 'shared/questionnaires/types'
import { copyTextToClipboard } from 'utils/clipboardUtils'
import { useFacDashData } from './FacilitatorDashboardState'

const EMPTY = '_EMPTY_'

export const getThemeFromResourceType = (type: AssessmentResource['userType']) => {
  switch (type) {
    case 'cadet':
      return 'blue'
    case 'parent':
      return 'parentGreen'
    case 'teacher':
      return 'orange'
    default:
      return 'gray'
  }
}

export const AssessmentMeasures: React.FC<NavRouteProps> = ({ route }) => {
  const { drupalProfile } = useUserState()
  const [providers, loadingProviders] = useFacDashData('providers', [])
  const [shareResourceGroup, setShareResourceGroup] = useState<AssessmentResourceGroup | null>(null)
  const [shareResource, setShareResource] = useState<AssessmentResource | null>(null)
  const [selectedProviderUid, setSelectedProviderUid] = useState<string | null>(null)
  const [showConfirmProviderModal, setShowConfirmProviderModal] = useState<boolean>(false)
  const launchWindowOnReRender = useRef<boolean>(false)

  const [_questionnaires /*, { loading: loadingQuestionnaires }*/] = useEndpoint<Questionnaire[]>(
    `/api/v1/questionnaires/list`,
    [],
    { refetchDespiteCachedValue: true }
  )

  const questionnaires = useMemo(
    () => (_questionnaires ? [..._questionnaires, observationalCodeQuestionnaire] : []),
    [_questionnaires]
  )

  useEffect(() => {
    if (!loadingProviders && providers.length === 1 && !selectedProviderUid) setSelectedProviderUid(providers[0].id)
  }, [loadingProviders, providers, selectedProviderUid])

  const providerOptions = [
    { label: 'Select provider...', value: EMPTY },
    ...providers.map((provider) => ({ label: provider.name, value: provider.id })),
  ]
  const selectedProvider = selectedProviderUid ? providers.find(({ id }) => id === selectedProviderUid) : undefined

  const questionnairesRelevantForSharing =
    shareResourceGroup && shareResource
      ? getQuestionnairesForBooklet({
          questionnaires: questionnaires || [],
          booklet: shareResourceGroup.booklet,
          userType: shareResource.userType,
        })
      : []

  const questionnaireUrl =
    drupalProfile && selectedProviderUid && shareResourceGroup && shareResource
      ? window.location.origin +
        `/public/questionnaire/${questionnairesRelevantForSharing[0]?.type || ''}/${shareResource.userType}/${shareResourceGroup.booklet}` +
        qs.stringify(
          { facilitator_id: drupalProfile.user_id, provider_uid: selectedProviderUid },
          { addQueryPrefix: true }
        )
      : null

  const handleStartResource = (url = questionnaireUrl) => {
    if (url) window.open(url, '_blank')
  }

  if (launchWindowOnReRender.current) {
    launchWindowOnReRender.current = false
    handleStartResource()
  }

  const handleDoItNow = (resource: AssessmentResource, resourceGroup: AssessmentResourceGroup) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShareResource(resource)
      setShareResourceGroup(resourceGroup)
      if (providers.length === 1) {
        setSelectedProviderUid(providers[0].id)
        launchWindowOnReRender.current = true
      } else {
        setShowConfirmProviderModal(true)
      }
    })
  }

  return (
    <>
      <Page route={route}>
        {assessmentMaterialsResources.map((resourceGroup, n) => (
          <Fragment key={n}>
            <HeaderHr children={resourceGroup.title} />
            {resourceGroup.booklet === 1 && (
              <>
                <P>
                  Pre-program Questionnaire Booklet 1 is typically completed during intake (through DO IT NOW or GET
                  LINK &amp; SHARE) before a group is established in the software. These questionnaires will be waiting
                  in the Unlinked Submissions list. To link pre-program questionnaires to a Cadet, please visit the
                  “Cadet Overview” page.
                </P>
                <P>
                  To support the intake process and use of pre-program Booklet 1, please refer to:
                  <ol style={{ marginTop: '0.5em' }}>
                    <li>Introductory Chapters of the Facilitator Manual,</li>
                    <li>The 'Intake Support' resources in the 'Resources' menu, and</li>
                    <li>The 'Assessment' unit of the software guide for instructions. </li>
                  </ol>
                </P>
              </>
            )}
            <Cells>
              {/* questionnaires.map */}
              {resourceGroup.resources.map((resource, i) => {
                const resourceQuestionnaires = getQuestionnairesForBooklet({
                  questionnaires: questionnaires || [],
                  userType: resource.userType,
                  booklet: resourceGroup.booklet,
                })
                if (resource.injectQuestionnaireKeys?.includes('observational-code')) {
                  resourceQuestionnaires.push(observationalCodeQuestionnaire)
                }
                return (
                  <Cell key={i} accent={getThemeFromResourceType(resource.userType)}>
                    <CellInner>
                      <Row flex="1 1 auto" alignItems="center">
                        <Column flex="none">
                          <H3>{resource.title}</H3>
                        </Column>
                        {resource.userType === 'cadet' && (
                          <>
                            <Column
                              className="tooltip-container"
                              flex="1 1 auto"
                              alignItems="flex-end"
                              style={{ paddingTop: 10 }}>
                              <>
                                <InfoTooltip
                                  placement="left"
                                  content={
                                    <span>
                                      Child Questionnaires: <br />
                                      These 3 options are for administering questionnaires only. Scores are manually
                                      entered in the cadet’s assessment overview screen.
                                      <br />
                                      Booklets 2, 3 & 4 are available to administer and score within live club meetings.
                                    </span>
                                  }
                                />
                                <Spacer size="s" />
                              </>
                            </Column>
                            <Spacer size="m" />
                          </>
                        )}
                      </Row>
                      <Row flex="none" flexWrap alignItems="center" justifyContent="center" style={{ gap: '10px 0' }}>
                        {resourceQuestionnaires.find(({ type }) => type === 'observational-code') && (
                          <>
                            <InfoTooltip
                              placement="left"
                              content={
                                <span>
                                  After completing the Observational Tool, you can use the Summary Table to guide a
                                  summary of child observations.
                                  <br />
                                  Please see the Facilitator Manual for more information.
                                </span>
                              }
                            />
                            <Spacer size="s" />
                            <AnchorButton
                              theme={getThemeFromResourceType(resource.userType)}
                              size="s"
                              href={require('./resources/assets/assessmentMeasures/ObservationalAssessmentSummaryTable.pdf')}
                              download={`Observational Assessment Summary Table`}
                              children="Summary Table"
                            />
                            <Spacer size="s" />
                          </>
                        )}
                        <AnchorButton
                          theme={getThemeFromResourceType(resource.userType)}
                          size="s"
                          href={resource.staticPdf}
                          download={resource.title}
                          children="Print &amp; Write"
                        />
                        {/*resource.interactivePdf && (
                        <>
                          <Spacer size="s" />
                          <AnchorButton
                            theme={getTheme(resource.type)}
                            size="s"
                            href={resource.interactivePdf}
                            download={`${resource.title}_interactive`}
                            children="Click & Send"
                          />
                        </>
                      )*/}
                        <Spacer size="s" />
                        <Button
                          theme={getThemeFromResourceType(resource.userType)}
                          size="s"
                          children="Do it now"
                          onClick={() => handleDoItNow(resource, resourceGroup)}
                        />
                        <Spacer size="s" />
                        <Button
                          theme={getThemeFromResourceType(resource.userType)}
                          size="s"
                          children="Get link &amp; share"
                          onClick={() =>
                            ReactDOM.unstable_batchedUpdates(() => {
                              setShareResource(resource)
                              setShareResourceGroup(resourceGroup)
                            })
                          }
                        />
                      </Row>
                    </CellInner>
                  </Cell>
                )
              })}
            </Cells>
            <Spacer size="l" />
          </Fragment>
        ))}
      </Page>
      {shareResource && !showConfirmProviderModal && (
        <Modal isOpen onRequestClose={() => setShareResource(null)}>
          <Panel
            shadow={false}
            padding="l"
            style={{ maxHeight: 'calc(100vh - 50px)', width: selectedProvider ? 'calc(100vw - 40px)' : 420 }}
            flair={[CUT_TL, CUT_TR]}>
            {selectedProviderUid ? (
              <>
                {selectedProvider && (
                  <P>
                    For provider: <strong>{selectedProvider.name}. </strong>
                    <ButtonLink role="button" onClick={() => setSelectedProviderUid(null)}>
                      Change?
                    </ButtonLink>
                  </P>
                )}
                <Row>
                  <TextInput
                    inputSize="s"
                    readOnly
                    disabled={!questionnaireUrl}
                    value={questionnaireUrl || 'Loading...'}
                    onFocus={(e) => e.target.select()}
                  />
                  <Spacer size="s" />
                  <Button
                    size="s"
                    disabled={!questionnaireUrl}
                    children="Copy"
                    onClick={() => questionnaireUrl && copyTextToClipboard(questionnaireUrl)}
                  />
                </Row>
                <Spacer size="l" />
                <Row justifyContent="center">
                  <Button size="s" theme="grey" children="Close" onClick={() => setShareResource(null)} />
                </Row>
              </>
            ) : (
              <>
                <Field
                  label={`Please select which provider the (future) unassigned assessment results should be stored under.`}>
                  <SelectField
                    empty={false}
                    options={providerOptions}
                    value={selectedProviderUid || EMPTY}
                    onChange={(value) => setSelectedProviderUid(value || EMPTY)}
                  />
                </Field>
              </>
            )}
          </Panel>
        </Modal>
      )}
      {showConfirmProviderModal && (
        <PromptModal
          isOpen
          label="Please confirm the provider which the assessment item you're about to do should be stored under"
          confirmText={!!selectedProviderUid ? 'Confirm & Start' : 'Select'}
          disableSubmit={!selectedProviderUid}
          onSubmit={(confirm) => {
            if (confirm) handleStartResource()
            ReactDOM.unstable_batchedUpdates(() => {
              setShareResource(null)
              setShareResourceGroup(null)
              setShowConfirmProviderModal(false)
            })
          }}>
          <SelectField
            empty={false}
            options={providerOptions}
            value={selectedProviderUid || EMPTY}
            onChange={(value) => setSelectedProviderUid(value || EMPTY)}
          />
        </PromptModal>
      )}
    </>
  )
}

const CellInner = styled(_CellInner)`
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    & ${H3} {
      margin-bottom: 10px;
    }
    & ${Column}.tooltip-container {
      padding-top: 0 !important;
    }
  }
`
